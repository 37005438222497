dl {
    display: flex;
    flex-flow: row wrap;
    border: solid var(--divider);
    border-width: 1px 1px 0 0;
    box-shadow: var(--box-shadow);  
}
dt {
    flex-basis: 20%;
    padding: 4px 8px;
    background: var(--main-blue);
    text-align: right;
    color: var(--text-light);
}
dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 4px 8px;
}


.storage-info-snippet {
    width: fit-content;
    height: fit-content;
    min-width: 200px;
    max-width: 400px;
    position: relative;
    border: 1px solid var(--divider);
    padding: 4px;
    border-radius: 8px;
}

.storage-info-snippet:hover {
    cursor: pointer;
}

.storage-info-snippet div p {
    color: var(--text-dark);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-normal);
}

.storage-info-snippet p {
    color: var(--text-dark-faded-extra);
    font-size: var(--font-size-sm);
}

.files-included-div {
    width: 100%;
    padding-right: 4px;
    text-align: right;
}

.files-included-div p i {
    color: var(--text-dark-faded-extra);
    font-size: var(--font-size-sm);
}

.storage-info {
    border-bottom: 1px solid var(--divider);
    background-color: var(--bg-light);
}

.storage-info p {
    color: var(--text-dark-faded-extra);
    font-size: var(--font-size-sm);
}

.storage-info p span,
.storage-info p time {
    color: var(--text-dark-faded);
}

.storage-info h1 { 
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-medium);
} 

.storage-file {
    width: 100px;
    height: 100px;
    overflow: hidden;
    text-align: center;
    text-wrap:wrap;
    border-radius: 8px;
    border: 1px solid var(--divider);
    padding: 8px;
    color: var(--text-dark-faded);
    transition: 0.2s;
    box-shadow: var(--box-shadow);
}
.storage-file svg { 
    font-size: var(--font-size-5xl); 
    color: var(--main-blue); 
    transition: 0.2s;
} 

.storage-file span {
    font-size: var(--font-size-xs);
} 

.storage-file:hover {
    background-color: var(--main-blue);
    color: var(--text-light);
}
.storage-file:hover svg {
    color: var(--text-light);
}

.storage-table{
    width: 100%;
}
.storage-table th {
    text-align: left;
}
.storage-table td {
    border-bottom: 1px solid var(--divider);
}
.storage-table td a {
    display: block;
    padding: 8px;
}
.storage-table td a:hover {
    background-color: var(--table-row-hover);    
}