.md-textarea-hint{
    color: var(--text-dark-faded-extra);
    font-size: var(--font-size-xs);
    text-align: right;
    align-items:baseline
}
.md-textarea{ 
    margin-top:4px;
    margin-bottom:0;
    width: 100%;    
}
.md-textarea-hint a{
    text-decoration: none;
    color: var(--text-dark-faded-extra);
}
.md-textarea-hint a:hover{
    text-decoration: underline;
    color: var(--text-dark-faded);
}
.md-textarea-preview {
    margin-top:4px;
    padding: 8px;
    width:100%;
    min-height: 150px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid var(--divider);
    background-color: var(--bg-light-faded);
}

.md-textarea-preview-h450 {
    max-height: 450px;
}