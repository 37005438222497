.nt-tips {
    width: 100%;
}

.nt-tips ol li {
    color: var(--text-dark-faded);
}

.KPI-wrapper {
    padding: 8px;    
    border-radius: 4px;
    background-color: var(--bg-light);
    box-shadow: var(--box-shadow);
}
.KPI-wrapper .kpi-title {
    font-size: var(--font-size-xs);
    color: var(--text-dark-faded);
}
.KPI-wrapper:hover {
    background-color: var(--main-blue);
}
.KPI-wrapper:hover * {
    color: var(--text-light);
}
