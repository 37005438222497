@keyframes ldio-z1ak9e2kq2a {
    0% {
        top: 96px;
        left: 96px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 18px;
        left: 18px;
        width: 156px;
        height: 156px;
        opacity: 0;
    }
}

.ldio-z1ak9e2kq2a div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-z1ak9e2kq2a 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio-z1ak9e2kq2a div:nth-child(1) {
    border-color: var(--main-blue);
    animation-delay: 0s;
}

.ldio-z1ak9e2kq2a div:nth-child(2) {
    border-color: var(--main-blue);
    animation-delay: -0.5s;
}
.ldio-z1ak9e2kq2a-2 div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-z1ak9e2kq2a 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio-z1ak9e2kq2a-2 div:nth-child(1) {
    border-color: var(--bg-light);
    animation-delay: 0s;
}

.ldio-z1ak9e2kq2a-2 div:nth-child(2) {
    border-color: var(--bg-light);
    animation-delay: -0.5s;
}

.loadingio-spinner-ripple-5jvviodeubo {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
}

.ldio-z1ak9e2kq2a {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-z1ak9e2kq2a div {
    box-sizing: content-box;
}
.ldio-z1ak9e2kq2a-2 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-z1ak9e2kq2a-2 div {
    box-sizing: content-box;
}
