a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}
/*

NavMenu
- div.sidebar-wrapper
  - div.menu-wrapper
    - div.nav-items-wrapper
	- div.nav-items-wrapper
*/

/* Sticky side bar */
.sidebar-wrapper {
    background-color: var(--main-blue);
    color: var(--text-light);   
    margin: 0;    
    display: flex;  
    align-items: stretch;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 250px;        
    width: 250px; 
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    bottom: 0;    
    overflow-x: hidden;  
    z-index: 500;
    transition: 0.2s; 
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.sidebar-wrapper-closed {
    width: 56px; 
    flex-basis: 56px;   
}

@media screen and (max-width: 768px) {
    .sidebar-wrapper {
        position: absolute;
    }
    .sidebar-wrapper-closed {
        width: 0; 
        flex-basis: 0;   
    }    
}

.menu-wrapper {  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 16px;  
}

.nav-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;/* 2x sidebar-wrapper width, to keep title inline */
}

.nav-item {
    text-decoration: none;
    color: var(--text-light-faded);
    width: 100%;
    transition: 0.15s;
}

.nav-item-selected {
    color: var(--text-light);
    background-color: var(--main-blue-light);
    border-left: 3px solid var(--bg-light);
}

.nav-link {
    text-decoration: none;
    color: inherit;
    width: 100%;
    height: 100%;
    font-size: var(--font-size-normal);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 8px;
    padding: 16px 16px;
    transition: 0.15s;
}

.nav-link-label {
    opacity: 1;
    transition: 0.2s; 
}
.nav-link-hidden-label {
    opacity: 0;
}

.nav-item:hover {
    cursor: pointer;
    color: var(--text-light);
    background-color: var(--main-blue-light);
}

.nav-item:disabled {
    cursor: auto;
}

.nav-logo-burger {
    display: flex;
    flex-direction: row;
}

.hamburger-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.hamburger .line {
    width: 18px; 
    height: 2px;
    background-color: var(--bg-light);
    display: block;
    margin: 3px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger:disabled {
    cursor: auto;
}

#hamburger-1.is-active .line:nth-child(2) {
    opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
    -webkit-transform: translateY(5px) rotate(45deg);
    -ms-transform: translateY(5px) rotate(45deg);
    -o-transform: translateY(5px) rotate(45deg);
    transform: translateY(5px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-5px) rotate(-45deg);
    -ms-transform: translateY(-5px) rotate(-45deg);
    -o-transform: translateY(-5px) rotate(-45deg);
    transform: translateY(-5px) rotate(-45deg);
}

