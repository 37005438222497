
.login-page-wrapper {    
    width: 100dvw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: var(--main-blue);
    flex: 1;
}

.login-wrapper {   
    padding: 32px;
    min-width: clamp(300px, 90%, 400px);
    border-radius: 8px;
    background-color: var(--bg-light);
    box-shadow: var(--box-shadow);
}

.login-bottom-links {
    font-size: var(--font-size-sm);
    color: var(--text-dark-faded);
    text-align: center;
}

.login-logo-wrapper {
    width: fit-content;
    height: 100%;
    padding: 16px 32px;
    margin: 0 auto;
    color: var(--main-blue);
}

.login-logo-wrapper h1 {
    font-size: var(--font-size-5xl);
    line-height: 0.75;
}

.login-logo-wrapper h2 {
    font-weight: var(--font-weight-light);
    margin: 0;
}

.login-page-wrapper .form-section:last-child {
    margin-bottom: 16px;
    margin-top: 32px;
}

.version-info {
    font-size: var(--font-size-sm);
    color: var(--text-dark-faded-extra);
    text-align: center;
    margin-top:4px;
}


.register-wrapper {
    margin-top: 64px;
    padding: 32px;
    min-width: 500px;
    border-radius: 8px;
    background-color: var(--bg-light);
    box-shadow: var(--box-shadow);
}

.register-wrapper h2 {
    margin: 0;
}

.register-logo {
    height: fit-content;
    width: fit-content;
    padding: 32px;
    color: var(--main-blue);
    margin: 0 auto;
}

.register-logo-wrapper {
    width: 100%;
    height: 100%;
}

.register-logo-wrapper h1 {
    font-size: var(--font-size-5xl);
    line-height: 0.75;
}

.register-logo-wrapper h2 {
    font-weight: var(--font-weight-light);
}

.help-tip {
    padding: 8px;
    border: 1px solid var(--divider);
    border-radius: 0 0 8px 8px;
}

.help-tip-row-wrapper {
    display: flex;
    flex-direction: row;
    color: var(--text-dark-faded);
}

.help-tip-row-wrapper p {
    font-size: var(--font-size-sm);
}

.field-correct {
    position: relative;
}

.field-correct-icon {
    position: absolute;
    right: 4px;
    top: 10px;
}

.login-bottom-links a {
    color: var(--text-dark-faded-extra);
    text-decoration: none;
    display: block;
    transition: 0.1s;
}
.login-bottom-links a:hover {
    color: var(--text-dark-faded);
}
