/* Provide sufficient contrast against white background */
html {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: var(--font-weight-regular);
    line-height: 1.429;
}

body {
    background-color: var(--bg-light-faded);
    color: var(--text-dark);
}

code {
    color: var(--code-text);
}

p {
    white-space: pre-line;
}

strong {
    font-weight: var(--font-weight-bold);
}

h1 {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
}

h2 {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
}

h3 {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
}

input,
button,
textarea,
select {
    border: 1px solid var(--divider);
}

input[type="checkbox"] {
    height: 16px;
    width: 16px;
    accent-color: var(--input-border-focus);
}

.hidden {
    display: none !important;
    visibility: hidden;
}

@media screen and (max-width: 768px) {
    .hidden-small {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .hidden-large {
        display: none !important;
    }
}

.rs-select {
    background-color: var(--bg-light);
    color: var(--text-dark);
}

.react-select-address-selected,
.react-select-address {
    font-size: var(--font-size-sm);
    color: var(--text-dark-faded-extra);
}

.react-select-address-selected {
    color: var(--text-light-faded-extra);
}


.rs-input {
    /* below css matches React-Select styles */
    min-height: 38px;
    padding: 2px 8px;
    color: var(--text-dark);
    border-color: var(--input-border);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    transition: 0.2s;
}

.rs-input:user-invalid {
    border-color: var(--input-border-invalid);
}

.rs-input:read-only,
.rs-input:disabled {
    background-color: var(--input-border);
}

select.rs-input:read-only {
    background-color: var(--input-bg);
}

select.rs-input:disabled {
    background-color: var(--input-border);
}

.rs-input:focus {
    border-color: var(--input-border-focus);
}

a {
    text-decoration: none;
    color: inherit;
}

.heading {
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
}

.sub-heading {
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
}

.sub-title {
    font-size: var(--font-size-sm);
    color: var(--text-dark-faded-extra);
}

.textarea {
    resize: both;
    overflow: auto;
}

.text-no-wrap {
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-faded {
    color: var(--text-dark-faded-extra);
}

.small {
    font-size: var(--font-size-sm);
}

.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.bg-light {
    background-color: var(--bg-light);
}

/* Full page - Wraps NavMenu & Page */

#root {
    width: 100dvw;
    min-height: 100dvh;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

/*
    Basic layout

    div#root
    - Topbar
    - div.layout-wrapper
        - div.sidebar-wrapper <NavMenu />
        - div.layout-content
*/

/* Page under the nav bar */
.layout-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex: 1;
    padding: 0;
    margin: 0;
    container-name: main-layout;
    container-type: size;
}

/* Inside layout-wrapper - Wraps Page */
.layout-content {
    flex: 1;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow-x: hidden;
    overflow-y: auto;
    container-name: main-page;
    container-type: size;
}

@media screen and (max-width: 768px) {
    #root {
        /* max-height:100dvh;*/
        max-width: 100dvw;
    }
}

/* Page wrapper */
.page-wrapper {
    flex: 1;
    box-sizing: border-box;
    position: relative;
}

.page-form {
    padding: 16px;
}

/* FORMS and INPUTS */

.form-section {
    width: 100%;
    margin-bottom: 16px;
}

.form-section-condensed {
    margin-bottom: 8px;
}

.form-section:first-child {
    margin-top: 16px;
}

/* input required */
.label-input-wrapper label:has(+input[required])::after,
.form-section label:has(+input[required])::after,
/* textarea required */
.label-input-wrapper label:has(+textarea[required])::after,
.form-section label:has(+textarea[required])::after,
/* select required */
.label-input-wrapper label:has(+select[required])::after,
.form-section label:has(+select[required])::after,
/* react select */
.label-input-wrapper label:has(+.react-select-container input[aria-required="true"])::after,
.form-section label:has(+.react-select-container input[aria-required="true"])::after {
    content: ' *';
    color: var(--text-dark-faded);
}

.form-section input,
.form-section select {
    padding: 8px;
    width: 100%;
}

.label-input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.button-input-wrapper button {
    margin-block: 16px;
}

.input-checkbox span.label-right {
    margin-left: 8px;
}

.input-checkbox span.label-left {
    margin-right: 8px;
}

/* End FORMS and INPUTS */

.full-height {
    height: 100% !important;
}

.fullscreen-overlay-clear,
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1250;
    background-color: var(--text-dark-faded-extra);
}

.fullscreen-overlay-clear {
    background-color: transparent;
}

.fullscreen-overlay:hover,
.fullscreen-overlay-clear:hover {
    cursor: default;
}


/* WIDTHS */

.width-100 {
    width: 100%;
}

.width-80 {
    width: 80%;
}

.width-20 {
    width: 20%;
}

.width-250px {
    width: 250px;
}

.min-width-50 {
    min-width: 50%;
}

.height-100 {
    height: 100%;
}

/* END OF WIDTHS */

/* PADDINGS */

.padding-none {
    padding: 0;
}

.padding-8 {
    padding: 8px;
}

.padding-16 {
    padding: 16px;
}

.padding-inline-8 {
    padding-inline: 8px;
    /* left and right*/
}

.padding-inline-16 {
    padding-inline: 16px;
}

.padding-vertical-8 {
    padding: 8px 0;
}

.padding-vertical-16 {
    padding: 16px 0;
}

.padding-horizontal-16 {
    padding: 0 16px;
}

.padding-top-16 {
    padding-top: 16px;
}

.padding-bottom-16 {
    padding-bottom: 16px;
}

/* END OF PADDINGS */

/* MARGINS */

.margin-vertical-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-bottom-64 {
    margin-bottom: 64px;
}

/* END OF MARGINS */

/* DIVIDERS */

.padding-inline-16 .divider-line,
.padding-16 .divider-line {
    margin-left: -16px;
    margin-right: -16px;
}

.padding-vertical-16 .divider-vertical-line,
.padding-16 .divider-vertical-line {
    margin-top: -16px;
    margin-bottom: -16px;
}

.divider-line-white {
    border-top: 1px solid var(--divider-light);
    width: 100%;
}

.divider-line-black {
    border-top: 1px solid var(--divider);
    width: 100%;
}

.divider-vertical-line-white {
    border-right: 1px solid var(--divider-light);
    height: 100%;
}

.divider-vertical-line-black {
    border-right: 1px solid var(--divider);
    height: 100%;
}

.divider-line-black-bottom {
    border-bottom: 1px solid var(--divider);
    width: 100%;
}

.divider-16 {
    padding: 16px 0;
}

/* END OF DIVIDERS */

/* BUTTONS */

.icon-btn-group,
.btn-group {
    display: flex;
    flex-direction: row;
}

.btn-group button {
    font-size: var(--font-size-sm) !important;
    padding: 4px 8px;
    border-radius: 0;
    border-right: 1px solid var(--divider-light);
}

.btn-group button:nth-last-child(1) {
    border-right: none;
}

.btn-action-button {
    position: absolute !important;
    bottom: 24px;
    right: 24px;
}

.button-contained-100,
.button-contained-20,
.button-contained {
    width: fit-content;
    height: fit-content;
    padding: 8px 16px;
    background-color: var(--main-blue);
    border: none;
    color: var(--text-light);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    text-decoration: none;
    transition: 0.15s;
}

.button-contained:hover {
    cursor: pointer;
    background-color: var(--main-blue-hover);
}

.button-contained:disabled {
    background-color: var(--main-blue-disabled);
    cursor: not-allowed;
}

.button-contained-100,
.button-contained-20 {
    height: 100%;
}

.button-contained-100 {
    width: 100%;
}

.button-contained-100:hover,
.button-contained-20:hover {
    cursor: pointer;
    background-color: var(--main-blue-hover);
}

.button-contained-100:disabled,
.button-contained-20:disabled {
    cursor: not-allowed;
    background-color: var(--main-blue-disabled);
}

.button-input,
.button-input-small,
.button-outlined,
.button-outlined-small,
.button-outlined-danger {
    height: fit-content;
    width: fit-content;
    background-color: transparent;
    border: 1px solid var(--main-blue);
    color: var(--text-dark);
    text-align: center;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-decoration: none;
    transition: 0.15s;
}

.button-outlined-danger {
    border: 1px solid var(--danger);
}

.button-input,
.button-input-small {
    border-color: var(--input-border);
}

.button-input,
.button-outlined,
.button-outlined-danger {
    padding: 8px 16px;
}

.button-input-small,
.button-outlined-small {
    padding: 2px 6px;
    font-size: var(--font-size-sm);
}

.button-input:hover,
.button-outlined:hover {
    background-color: var(--main-blue);
    color: var(--text-light);
    cursor: pointer;
}

.button-outlined-danger:hover {
    background-color: var(--danger-hover);
    color: var(--text-light);
}

.button-input-small:hover,
.button-outlined-small:hover {
    border: 1px solid var(--main-blue);
    cursor: pointer;
}

.button-outlined:disabled,
.button-outlined-small:disabled {
    cursor: not-allowed;
    background-color: var(--main-blue-disabled);
}

.button-outlined-danger:disabled {
    cursor: not-allowed;
    background-color: var(--danger-disabled);
}

.button-input:disabled,
.button-input-small:disabled {
    cursor: not-allowed;
    background-color: var(--input-border);
}

.button-contained-danger,
.button-contained-warning,
.button-contained-success {
    height: fit-content;
    min-width: max-content;
    padding: 8px 16px;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    gap: 8px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.15s;
}

.button-contained-danger {
    background-color: var(--danger);
    color: var(--text-light);
}

.button-contained-danger:hover {
    cursor: pointer;
    background-color: var(--danger-hover);
}

.button-contained-danger:disabled {
    cursor: not-allowed;
    background-color: var(--danger-disabled);
}

.button-contained-warning {
    background-color: var(--warning);
    color: var(--text-dark);
}

.button-contained-warning:hover {
    cursor: pointer;
    background-color: var(--warning-hover);
}

.button-contained-warning:disabled {
    cursor: not-allowed;
    background-color: var(--warning-disabled);
}

.button-contained-success {
    background-color: var(--success);
    color: var(--text-light);
}

.button-contained-success:hover {
    cursor: pointer;
    background-color: var(--success-hover);
}

.button-contained-success:disabled {
    cursor: not-allowed;
    background-color: var(--success-disabled);
}

.button-outlined-blue-square {
    height: 38px;
    width: 38px;
    padding: 8px 8px;
    border: 1px solid var(--main-blue);
    color: var(--text-dark);
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    text-decoration: none;
    transition: 0.15s;
}

.button-outlined-blue-square:hover {
    cursor: pointer;
    background-color: var(--main-blue);
    color: var(--text-light);
}

.button-outlined-blue-square:disabled {
    cursor: not-allowed;
    background-color: var(--success-disabled);
}

.icon-btn-small svg {
    font-size: var(--font-size-normal);
}

.icon-danger {
    color: var(--danger);
}

.icon-success {
    color: var(--success);
}

.icon-warning {
    color: var(--warning);
}

.icon-primary {
    color: var(--main-blue);
}

.icon-btn svg:hover {
    color: color-mix(in srgb, currentcolor, var(--dark-color) 20%);
}

.button-small {
    padding: 2px 6px;
    font-size: var(--font-size-sm);
}

/* END OF BUTTONS */

/* BORDERS */

.border-bottom {
    border-bottom: 1px solid var(--divider);
}

body::-webkit-scrollbar {
    width: 1em;
}

.transparent-full-screen {
    position: fixed;
    /*width: 100dvw;
    height: 100dvh;*/
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 10;
}

.alert {
    --_bg-color: var(--bg-light);
    --_text-color: var(--text-dark);
    display: flex;
    /*
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    justify-items: right;*/
    gap: 8px;
    border-radius: 4px;
    padding: 8px;
    background-color: var(--_bg-color);
    color: color-mix(in srgb, var(--_text-color), var(--text-dark) 40%);
}

.alert-warning {
    --_bg-color: var(--warning-bg);
    --_text-color: var(--warning);
}

.alert-danger {
    --_bg-color: var(--danger-bg);
    --_text-color: var(--danger);
}

.alert-success {
    --_bg-color: var(--success-bg);
    --_text-color: var(--success);
}

.alert-primary {
    --_bg-color: var(--main-blue-bg);
    --_text-color: var(--main-blue);
}

.pill,
.pill-primary,
.pill-success,
.pill-danger {
    font-size: var(--font-size-xs);
    padding: 4px 8px;
    border-radius: 4px;
}

.pill-danger {
    background-color: var(--danger);
    color: var(--text-light);
}

.pill-success {
    background-color: var(--success);
    color: var(--text-light);
}

.pill-primary {
    background-color: var(--main-blue);
    color: var(--text-light);
}

.faded-icon {
    color: var(--text-dark-faded-extra);
    transition: 0.15s;
}

.faded-icon:hover {
    color: var(--text-dark);
    cursor: pointer;
}

.faded-icon:disabled {
    cursor: auto;
}

.faded-icon-small {
    max-width: 16px;
    max-height: 16px;
    color: var(--text-dark-faded-extra);
    transition: 0.15s;
}

.faded-icon-small:hover {
    color: var(--text-dark);
    cursor: pointer;
}

.faded-icon-small:disabled {
    cursor: auto;
}

.text-hover:hover {
    color: var(--text-dark-faded);
    transition: 0.15s;
}

.text-hover:hover {
    color: var(--text-dark);
    cursor: pointer;
}

.text-hover:disabled {
    cursor: auto;
}

.text-hint {
    color: var(--text-dark-faded-extra);
    font-size: var(--font-size-sm);
}

.border-radius-0 {
    border-radius: 0;
}

/* DATA TABLE CLASSES */
.data-table {
    width: 100%;
    padding: 16px;
    border-style: none !important;
}

.data-table .MuiDataGrid-columnHeaderTitle {
    font-size: var(--font-size-normal);
}

.data-table .MuiDataGrid-toolbarContainer {
    padding: 4px;
    background-color: var(--bg-light);
    border: 1px solid var(--divider);
    border-radius: 4px;
    margin-bottom: 8px;
}

@media print {
    .data-table .MuiDataGrid-toolbarContainer {
        display: none;
        visibility: hidden;
    }
}

.data-table-row {
    border-style: none;
    border-top: 1px solid var(--divider);
}

.data-table-cell {
    position: relative;
    border-style: none !important;
    padding: 4px;
    font-size: var(--font-size-sm);

    font-family: var(--font-family);
    font-weight: var(--font-weight-regular);
    color: var(--text-dark);
}

.data-table-highlight {
    background-color: var(--table-row-highlight);
}

.data-table-row:hover .data-table-cell {
    background-color: var(--table-row-hover);
}

.data-table-cell a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.data-table-cell.MuiDataGrid-cell--textRight a {
    text-align: right;
    justify-content: right;
}

.data-table-cell.MuiDataGrid-cell--textLeft a {
    text-align: left;
    justify-content: left;
}

.data-table-cell.MuiDataGrid-cell--textCenter a {
    text-align: center;
    justify-content: center;
}

/* re-set the checkbox to the mui default */
.data-table-cell.MuiDataGrid-cellCheckbox input[type="checkbox"],
.MuiDataGrid-checkboxInput input[type="checkbox"] {
    width: 100%;
    height: 100%;
}

/* 404 PAGE */

.center-404 {
    align-items: center;
    gap: 0px;
    display: flex;
    flex-direction: column;
}

.center-404 p,
.center-404 h1,
.center-404 h3 {
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
}

.center-404 h1 {
    color: var(--main-blue);
    font-size: var(--font-size-huge);
    line-height: 125px;
}

.center-404 h3 {
    font-size: var(--font-size-3xl);
}

.center-404 p {
    font-size: var(--font-size-xl);
    padding-top: 32px;
}

/* FLEX CLASSES */

.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
    position: relative;
    /* why? */
    gap: 8px;

}

.flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
}

.flex-row-stretch {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    gap: 8px;
}

.flex-align-items-center {
    align-items: center;
}

.flex-align-self-stretch {
    align-self: stretch;
}

.flex-align-self-center {
    align-self: center;
}

.flex-row-center-vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.flex-row-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
}

.flex-row-spacebetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.flex-column-stretch {
    align-items: stretch;
}

.flex-column-sspacebetween {
    justify-content: space-between;
}

.flex-center-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-spacebetween {
    justify-content: space-between;
}

.flex-spacearound {
    justify-content: space-around;
    justify-items: right;
}

.flex-right-align {
    justify-content: right;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-gap-none {
    gap: 0;
}

.flex-gap-2 {
    gap: 2px;
}

.flex-gap-4 {
    gap: 4px;
}

.flex-gap-8 {
    gap: 8px;
}

.flex-gap-16 {
    gap: 16px;
}

.flex-gap-32 {
    gap: 32px;
}

.flex-gap-64 {
    gap: 64px;
}

.flex-20 {
    flex: 0.20;
}

.flex-30 {
    flex: 0.30;
}

.flex-50 {
    flex: 0.50;
}

.flex-70 {
    flex: 0.70;
}

.flex-80 {
    flex: 0.80;
}

.flex-100 {
    flex: 1;
}

@media screen and (max-width: 768px) {
    .flex-column-small {
        flex-direction: column;
    }

    .flex-row-small {
        flex-direction: row;
    }

    .flex-column-reverse-small {
        flex-direction: column-reverse !important;
    }

    .flex-column-small .flex-100 {
        flex: 1 1 100%;
    }

    .data-table {
        padding: 8px 0 16px 0;
    }

    .flex-end-small {
        justify-content: flex-end;
    }

}

/* MARKDOWN CLASSES */

.markdown {
    word-wrap: anywhere;
    width: 100%;
    box-sizing: border-box;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.markdown blockquote,
.markdown code,
.markdown ul,
.markdown ol,
.markdown p,
.markdown div {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    white-space: normal;
}

.markdown code,
.markdown pre {
    white-space: preserve;
}

.markdown div.markdown-alert p {
    padding-top: unset;
    padding-bottom: unset;
    margin-bottom: unset;
}

.markdown tr,
.markdown td {
    white-space-collapse: preserve-breaks;
}

/* P with a direct sibling p */
.markdown p:has(+p) {
    margin-bottom: unset;
}

.markdown blockquote p:first-child,
.markdown code p:first-child,
.markdown li p:first-child,
.markdown p:first-child,
.markdown h1:first-child,
.markdown h2:first-child,
.markdown h3:first-child,
.markdown h4:first-child,
.markdown h5:first-child,
.markdown h6:first-child {
    padding-top: unset;
}

.markdown blockquote p:last-child,
.markdown code p:last-child,
.markdown li p:last-child,
.markdown p:last-child {
    padding-bottom: unset;
}

.markdown blockquote p,
.markdown code p,
.markdown li p {
    margin-bottom: unset;
}

.markdown hr {
    color: var(--divider);
    margin-top: 16px;
    margin-bottom: 16px;
}

.markdown a {
    color: var(--main-blue);
    transition: 0.15s;
}

.markdown a:hover {
    color: var(--main-blue-light);
}

.markdown li p {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.slate-editable abbr,
.markdown blockquote,
.markdown div.markdown-alert {
    --_alert-border: var(--main-blue);
    --_alert-bg: var(--main-blue-bg);
    --_alert-text: var(--main-blue);

    border-left: solid 4px var(--_alert-border);
    padding: 8px;
    font-weight: var(--font-weight-medium);
    background-color: var(--_alert-bg);
    margin-top: 8px;
    margin-bottom: 8px;
}

.markdown table {
    border-collapse: collapse;
    min-width: 33%;
    margin-top: 8px;
    margin-bottom: 16px;
    box-shadow: var(--box-shadow);
    background-color: var(--bg-light);
}

.markdown table,
.markdown td {
    border: 1px solid var(--divider);
}

.markdown th,
.markdown td {
    padding: 4px;
    transition: 0.15s;
    word-wrap: break-word;
}

.markdown th {
    background-color: var(--main-blue);
    border: 1px solid var(--main-blue);
    color: var(--text-light);
    font-weight: var(--font-weight-medium);
}

.markdown tr:hover {
    background-color: var(--table-row-hover);
}

/* the first item in the markdown is a list, so remove the top padding */
.markdown>ul:first-child,
.markdown>ol:first-child {
    padding-top: 0;
    margin-top: 0;
}

/* list folling a header */
.markdown h1+ul,
.markdown h2+ul,
.markdown h3+ul,
.markdown h4+ul,
.markdown h5+ul,
.markdown h6+ul,
.markdown h1+ol,
.markdown h2+ol,
.markdown h3+ol,
.markdown h4+ol,
.markdown h5+ol,
.markdown h6+ol {
    padding-top: 0;
    margin-top: 0;
}

.markdown a[href^="#mention_"] {
    color: var(--text-dark);
    font-weight: var(--font-weight-medium);
    background-color: var(--main-blue-faded-extra);
    border-radius: 4px;
    padding-inline: 4px;
    pointer-events: none;
}

.markdown a[href^="#mention_"]:hover {
    color: var(--text-dark-faded);
}

.markdown a[href^="#mention_"]::before {
    content: "@";
}

/* markdown alerts */
.markdown-alert>:last-child {
    margin-bottom: 0 !important
}

.markdown-alert .markdown-alert-title {
    align-items: center;
    display: flex;
    color: var(--_alert-text);
    font-weight: var(--font-weight-bold);
}

.markdown-alert .markdown-alert-title svg.octicon {
    fill: currentColor;
    margin-right: 8px;
}

.markdown-alert .markdown-alert-title,
.markdown div.markdown-alert {
    color: color-mix(in srgb, var(--_alert-text), var(--text-dark) 40%);
}

.markdown div.markdown-alert.markdown-alert-note {
    --_alert-border: var(--main-blue);
    --_alert-bg: var(--main-blue-bg);
    --_alert-text: var(--main-blue);
}

.markdown div.markdown-alert.markdown-alert-tip {
    --_alert-text: var(--success);
    --_alert-border: var(--success);
    --_alert-bg: var(--success-bg);
}

.markdown div.markdown-alert.markdown-alert-important {
    --_alert-text: var(--secondary);
    /* TODO important */
    --_alert-border: var(--secondary);
    --_alert-bg: var(--secondary-bg);
}

.markdown div.markdown-alert.markdown-alert-warning {
    --_alert-text: var(--warning);
    /* TODO warning title */
    --_alert-border: var(--warning);
    --_alert-bg: var(--warning-bg);
}

.markdown div.markdown-alert.markdown-alert-caution {
    --_alert-text: var(--danger);
    --_alert-border: var(--danger);
    --_alert-bg: var(--danger-bg);
}

/* END OF MARKDOWN */

/* STYLED LIST */

ul.styled-list,
ol.styled-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul.styled-list li,
ol.styled-list li {
    margin-bottom: 8px;
    padding: 8px;
    border-left: solid 4px var(--main-blue);
}

ul.styled-list li:last-child,
ol.styled-list li:last-child {
    margin-bottom: unset;
}

.added-file {
    min-height: 64px;
    min-width: 64px;
    max-width: 96px;
    border: 1px solid var(--divider-dark);
    border-radius: 4px;
    padding: 4px;
    transition: 0.15s;
}

.added-file:hover {
    cursor: pointer;
    border-color: var(--danger-hover);
}

.added-file p {
    width: 100%;
    text-align: center;
    font-size: var(--font-size-xs);
    color: var(--text-dark-faded-extra);
    word-wrap: break-word;
}

.clickable {
    /* use for onclick events to get the correct pointer */
    cursor: pointer;
}

/* print styles */
@media print {
    .hidden-print {
        display: none;
        visibility: hidden;
    }

    .sidebar-wrapper {
        display: none;
        visibility: hidden;
    }

    .layout-wrapper {
        align-items: start;
        justify-content: start;
        flex-direction: column;
        container-type: unset;
    }

    .layout-content {
        overflow: unset;
        container-type: unset;
    }
}