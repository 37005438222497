.loading-wrapper {
    width: 100vw;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--text-dark-faded-extra);
}

.center {
    margin: 0 auto;
}

/* TOP BAR STYLES */
.top-bar-wrapper {
    padding: 8px;
    background-color: var(--bg-light);
    display: flex;
    justify-content: space-between;
    gap: 8px;    
    margin: 0; 
    container-type: normal;
    container-name: topbar;
}

.top-bar-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.topbar-info {
    color: var(--text-dark-faded);
    padding: 4px;
    height: fit-content;
    position: relative;
    border-radius: 8px;
}
.topbar-info p b {
    font-size: var(--font-size-xs);
}
.topbar-info p {
    font-size: var(--font-size-xs);
}

.ticket-org-info-arrow {
    width: fit-content;
    height: 24px;
    position: absolute;
    padding: 4px;
    top: 100%;
    left: 45%;
    background-color: var(--bg-light-faded);
    border-bottom: 1px solid var(--divider);
    border-right: 1px solid var(--divider);
    border-left: 1px solid var(--divider);
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    z-index: 99;
}

/* TOP BAR STYLES END */

/* FULL WIDTH ALERT */

.full-width-alert {
    width: 100%;
    padding: 16px;
    background-color: var(--danger);
    color: var(--text-light);
}

/* TICK AND CROSS NOTIFICATION CLASSES */

.notification-cross,
.notification-tick  {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 5;
    border-radius: 100%;
    line-height: 1;    
}

.notification-tick {
    background-color: var(--success);
    color: var(--text-light);
}

.notification-cross {    
    background-color: var(--danger);
    color: var(--text-light);
}

.notification-number {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-xs);
    min-width: 20px; 
    line-height: 1;
    padding: 0 6px;
    height: 20px;
    border-radius: 10px;
    z-index: 5;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: var(--danger);
    color: var(--text-light);
    top: 0;
    right: 0;
    -webkit-transform: scale(1) translate(50%, -50%);
    -moz-transform: scale(1) translate(50%, -50%);
    -ms-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
}

.notification-number-indent {
    top: 10px;
    right: 10px; 
}

.notification-outer-wrapper {
    height: fit-content;
    position: absolute;
    top: 50px;
    right: 1%;
    border-radius: 8px;
    z-index: 1400;
}

.notifications-wrapper {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;    
    min-height: 50px;
    width: 400px;
    right: 0;
    margin-top: 8px;
    color: var(--text-dark);
    background-color: var(--bg-light-faded);
    border: 1px solid var(--main-blue);
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    position: relative;
    display: flex;
    flex-direction: column;
}

.notifications-items {
    max-height: 300px;
    overflow-y: scroll;
}
.notifications-no-items {
    height: 100%;
    max-height: 300px;    
    text-align: center;
    flex: 1;    
}
.notifications-no-items p {
    padding: 0 4px 4px 4px;
    color: var(--screen-blackout);
    font-size: var(--font-size-sm);
}
.notifications-no-items p:first-child {
    font-size: var(--font-size-lg);    
    padding: 4px;
}
.notifications-no-items p a {
    color: var(--main-blue)
}
.notifications-no-items p a:hover {
    color: var(--main-blue-light);
}

.notifications-quick-btns {
    width: 100%;
    border-radius: 8px;
    position: sticky;
    bottom: 0;
    background-color: var(--bg-light-faded);
    z-index: 11;
}

.notification {
    font-size: var(--font-size-sm);
    position: relative;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.notification-dt {
    font-size: var(--font-size-xs);
    color: var(--text-dark-faded-extra);
}

.notification .notification-info:hover {
    background-color: var(--main-blue);
    color: var(--text-light);
    cursor: pointer;
}

.notification .notification-info:hover .notification-dt {
    color: var(--text-light);
}

.notification .notification-info:disabled {
    cursor: auto;
}

.notification-info {
    padding: 16px 16px;
    width: 100%;
}

.notification-delete-btn {
    padding: 16px 16px;
    color: var(--text-dark-faded-extra);
    z-index: 11;
    transition: 0.2s;
}

.notification-delete-btn:hover {
    color: var(--text-light);
    background-color: var(--danger);   
    cursor: pointer;
}

.notification-clear-btn {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 8px 8px;
    border-top: 1px solid var(--main-blue);
    color: var(--text-dark);
    background-color: transparent;
    border-radius: 0 0 8px 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    text-decoration: none;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.notification-clear-btn:hover {
    cursor: pointer;
    background-color: var(--main-blue);
    color: var(--text-light);
}

.notification-clear-btn:disabled {
    cursor: auto;
}

/* Data Snippet Classes */

.data-snippet-wrapper {
    width: fit-content;    
    height: fit-content;
}

.ds-fixed-width {
    width: 150px !important;
}

.ds-border {
    border-bottom: 1px solid var(--divider);
}

.data-snippet-wrapper div p {
    color: var(--text-dark);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-normal);
}

.data-snippet-wrapper p {
    color: var(--text-dark-faded-extra);
    font-size: var(--font-size-sm);
}

#data-snippet-icon {
    height: 16px;
    width: 16px;
}

/* THREE DOTS */
.three-dots {
    padding: 8px;
}

.three-dots:hover {
    cursor: pointer;
}

.three-dots:disabled {
    cursor: auto;
}

/* DROPDOWN */


.dropdown-wrapper,
.dropdown-wrapper-large {
    width: fit-content;
    height: fit-content;
    position: absolute;
    z-index: 99;
    background-color: var(--bg-light);
    border: 1px solid var(--main-blue);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}

.dropdown-wrapper {
    top: 16px;
    right: 105%;
}

.dropdown-wrapper-large {
    min-width: 250px;    
    top: 120%;
    left: 0%;    
}

.dropdown-option {
    padding: 8px 16px;
    width: 100%;
}

.dropdown-option:hover {
    background-color: var(--main-blue-bg);
    cursor: pointer;
}

.dropdown-option:disabled {
    cursor: auto;
}

.dropdown-option p {
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-regular);
}

.dropdown-container {
    position: relative;    
}
.dropdown-container p {
    font-size: var(--font-size-sm);
}

/* Confirmation Modal */

.alert-dialog-wrapper {
    min-width: 350px;
}
.alert-dialog-wrapper-overflow-y-fix > div {
    /* MUI adds overflow-y to the body that tops react-select from overflowing the dialog */
    overflow-y: unset;
}

/* Popup Modal */

.popup-modal {
    width: fit-content;
    height: fit-content;
    min-width: 300px;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--bg-light);
    border: 3px solid var(--main-blue);
    z-index: 999;
    box-shadow: var(--box-shadow);
}

.popup-modal div textarea {
    resize: none;
    width: 300px;
}

/* OTHER */
.org-data-snippet span {
    color: var(--text-dark-faded-extra);
    font-size: var(--font-size-xs);
}
.org-data-snippet{
    font-size: var(--font-size-sm);
    line-height: var(--font-size-sm);
}

.ticket-name {
    width: 100%;
}


/* Cards */

.accordion-card-wrapper .card-header {
    flex-direction: row-reverse;
}

.card-section {
    border-bottom: 1px solid var(--divider);    
}
.card-section > .card-section-link {
    display: block;
    text-decoration: none;
    transition: 0.15s;
    padding: 8px 16px;
    color: var(--text-dark);
}
.card-section > .card-section-link:hover {
    background-color: var(--main-blue-bg);
}
.card-content .card-section {
    /* remove mui padding for CardContent */
    margin-left: -16px;
    margin-right: -16px;
}
.card-content .card-section:last-child {
    border-color: transparent;
    margin-bottom: -16px;
}
.card-content .card-section:first-child {
    border-top: 1px solid var(--divider);    
}

.card-content h1,
.card-content h2,
.card-content h3 { 
    font-weight: var(--font-weight-medium);
}

.card-header h1,
.card-header h2,
.card-header h3 { 
    font-weight: var(--font-weight-regular);
}

/* dropzone (.dropzone) */
.dropzone {
    position: relative; 
}
.dropzone-files * {
    max-height: 64px;
    text-align: center;
}
.dropzone-target {
    min-height: 100px;    
    border-radius: 8px;
    padding: 16px;
    display: flex;
}
.dropzone-target:hover {
    cursor: pointer;
}

.dropzone-target, 
.dropzone-hint {
    border: 2px dashed var(--divider-dark);
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dropzone-hint {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    z-index: 999;
    display: none;
}

.dropzone-hint,
.dropzone-over .dropzone-target {
    background-color: var(--main-blue-bg);
} 

.dropzone-over .dropzone-hint {
    opacity: 1;
    display: flex;
}
