/**
 Button colors taken from : https://colorchef.vercel.app/buttons
*/

/*
base variables
*/
:root {
    --light-color: #fff;
    --dark-color: #222;

    /* if updating this, also update Theme.js  */
    --primary-color: #3399cc;
    --secondary-color: #B233CC;
    --success-color: #41a447;
    --warning-color: #feba32;
    --danger-color: #cf1c1c;   

    --code-text: #e01a76;    
    --ticket-high-priority: #ffdaa3;
    --ticket-urgent-priority: #ffc1b6; 

    --body-color: #eeeeee;   /* MUI Grey[200] */
    --input-border: #bdbdbd; /* MUI Grey[400] */    
}


/*
 Dark mode colors
*
@media (prefers-color-scheme: dark) {
    :root{
        --light-color: #222;
        --dark-color: #fff;
        --body-color: #131313;
    }
}
*/

/*
 variables after dark mode switch
    Hover: 20% Dark
    Disabled: 20% Light
    Background: 80% light 

 Note: in color-mix "transparent 60%" = 0.4 in RGBA

 For MUI defaults 
 @see https://mui.com/material-ui/customization/default-theme/
*/
:root {
    
    --main-blue: var(--primary-color);
    --main-blue-light: color-mix(in srgb, var(--main-blue), var(--light-color) 30%);
    --main-blue-hover: color-mix(in srgb, var(--main-blue), var(--dark-color) 20%);
    --main-blue-disabled: color-mix(in srgb, var(--main-blue), var(--light-color) 60%);
    --main-blue-bg: color-mix(in srgb, var(--main-blue), var(--light-color) 85%);
    --main-blue-faded-extra: color-mix(in srgb, var(--main-blue), transparent 60%);

    --secondary: var(--secondary-color);
    --secondary-hover: color-mix(in srgb, var(--secondary), var(--dark-color) 20%);
    --secondary-disabled: color-mix(in srgb, var(--secondary), var(--light-color) 60%);
    --secondary-bg: color-mix(in srgb, var(--secondary), var(--light-color) 85%);

    --success: var(--success-color);
    --success-hover: color-mix(in srgb, var(--success), var(--dark-color) 20%);
    --success-disabled: color-mix(in srgb, var(--success), var(--light-color) 60%);
    --success-bg: color-mix(in srgb, var(--success), var(--light-color) 85%);

    --danger: var(--danger-color);
    --danger-hover: color-mix(in srgb, var(--danger), var(--dark-color) 20%);
    --danger-disabled: color-mix(in srgb, var(--danger), var(--light-color) 60%);
    --danger-bg: color-mix(in srgb, var(--danger), var(--light-color) 85%);

    --warning: var(--warning-color);
    --warning-hover: color-mix(in srgb, var(--warning), var(--dark-color) 20%);
    --warning-disabled: color-mix(in srgb, var(--warning), var(--light-color) 60%);
    --warning-bg: color-mix(in srgb, var( --warning), var(--light-color) 85%);

    --text-light: var(--light-color);
    --text-light-faded: color-mix(in srgb, var(--light-color), transparent 40%)
    --text-light-faded-extra: color-mix(in srgb, var(--light-color), transparent 50%);

    --text-dark: var(--dark-color);    ;
    --text-dark-faded: color-mix(in srgb, var(--dark-color), transparent 25%);
    --text-dark-faded-extra: color-mix(in srgb, var(--dark-color), transparent 50%);

    --bg-light: var(--light-color);     
    --bg-light-faded: var(--body-color); /* TODO proper name for this var */

    /* 
        typography (if updating this, also update Theme.js) 
        Font scale from - https://typescale.com/
    */
    --font-family: 'Open Sans', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

    /* Ration: 1:1.2*/
    --font-size-huge: 10.699rem;
    --font-size-5xl: 2.986rem; 
    --font-size-4xl: 2.488rem; /* h1 */
    --font-size-3xl: 2.074rem; 
    --font-size-2xl: 1.728rem; 
    --font-size-xl: 1.44rem; /* h2 */
    --font-size-lg: 1.2rem; /* h3 */
    --font-size-normal: 1rem;
    --font-size-sm: 0.833rem;
    --font-size-xs: 0.694rem;

    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;


    /* components and controls */ 

    --divider: color-mix(in srgb, var(--dark-color), transparent 90%);
    --divider-dark: color-mix(in srgb, var(--dark-color), transparent 60%); 
    --divider-light: color-mix(in srgb, var(--light-color), transparent 60%);    
    
    --table-row-hover: color-mix(in srgb, var(--main-blue), transparent 91%);
    --table-row-highlight: var(--success-bg);

    /* inputs and other controls */
    --input-border-focus: var(--main-blue);
    --input-border-invalid: var(--danger);
    --select-background-hover: var(--main-blue-bg);
    --input-bg: var(--light-color);

    /* --box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);*/
    --box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 
                  0px 1px 1px 0px rgba(0,0,0,0.14), 
                  0px 1px 3px 0px rgba(0,0,0,0.12);

    --screen-blackout: rgba(0, 0, 0, 0.5);/* NO NOT ADD DARK MODE VERSION */
}