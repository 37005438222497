
.dashboard-top-row-container,
.dashboard-row-container {
    padding: 16px;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
}

.dashboard-full-notice {
    width: 100%;
    height: 100%;
    padding-top: 128px;
}

.dashboard-full-notice p,
.error-full-notice p {
    font-size: var(--font-size-3xl);
    width: fit-content;
    color: var(--screen-blackout);
    margin: 0 auto;
}

/* DASHBOARD QUICK GLANCE */

.dashboard-top-row-container .dashboard-button {
    height:128px;
    container-type: size;
    container-name: dashboard-button; 
}

.dashboard-button {
    border-radius: 8px;
    border: 2px solid var(--main-blue);
    background-color: var(--bg-light);
    color: var(--text-dark);
    flex: 1 0 200px;
    max-width:200px;
    min-height: 64px;
    max-height: 128px;    
    transition: 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    box-shadow: var(--box-shadow);    
    
}

.dashboard-button:hover {
    background-color: var(--main-blue);
    color: var( --text-light);    
}

.dashboard-clickable {
    cursor: pointer;
}

.dashboard-quick-glance-button { 
    background-color: var(--main-blue);
    color: var( --text-light);
}

.db-icon {
    height: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--main-blue);
    color: var( --text-light);
}
.db-icon svg {
    width: 2rem; 
    height: 2rem;
}
.db-text {
    height: 100%;
    width:100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
    text-transform: capitalize;
}
.db-icon + .db-text {
    padding-left: 8px;
}

.dashboard-quick-glance-button .db-stat {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
}
.dashboard-quick-glance-button:hover{
    background-color: var(--bg-light);
    color: var(--text-dark);
}

.dashboard-progress-bar {
    --_progress-value: 75%;
    --_background-color: var(--bg-light);
    --_progress-color: var(--main-blue);
    color: var(--text-dark);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 95cqmin;
    height: 95cqmin;
    border-radius: 50%;
    background: 
      radial-gradient(closest-side, var(--_background-color) 79%, transparent 80% 100%),
      conic-gradient(var(--_progress-color) var(--_progress-value), var(--main-blue-light) 0);    
}
.dashboard-progress-bar-text {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
}  

.dashboard-button:hover .dashboard-progress-bar {
    --_background-color: var(--main-blue);
    --_progress-color: var(--bg-light);
    color: var( --text-light); 
}


.dashboard-button-success {
    border-color: var(--main-blue);
    background-color: var(--bg-light);
    color: var(--text-dark); 
}
.dashboard-button-success:hover {
    border-color: var(--main-blue);
    background-color: var(--main-blue);
    color: var(--text-light); 
}

.dashboard-button-warning {
    border-color: var(--warning-bg);
    background-color: var(--warning-bg);
    color: var(--text-dark); 
}
.dashboard-button-danger {
    border-color: var(--dangerbg);
    background-color: var(--danger-bg);
    color: var(--text-dark); 
}

@media screen and (max-width: 768px) {
    .dashboard-button {
        flex-basis: 40%;
        max-width: unset;      
    }
    .dashboard-row-container,
    .dashboard-top-row-container {
        justify-content: space-around;
    }
}