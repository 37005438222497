.settings-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    row-gap: 16px;
    column-gap: 16px;
    grid-row: auto auto;
}

@media screen and (width <= 768px) {
    .settings-wrapper {
        grid-template-columns: 100%;
    }
}
