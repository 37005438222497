.ticket-page-wrapper {
    width: 100%;
    height: 100%;
}

/* Wrapper for the ticket messages/ textarea and the side bar */
.ticket-content-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    align-items: stretch;
    align-content: stretch;
    gap: 0;
}

/* Wrapper for the ticket messages/ textarea */
.ticket-messages-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.ticket-sidebar-wrapper {
    width: 250px;
    background-color: var(--bg-light);
}

@media screen and (max-width: 768px) {
    .ticket-content-wrapper {
        flex-direction: column;
    }

    .ticket-sidebar-wrapper {
        width: unset;
    }

    .ticket-sidebar-edits {
        display: grid;
        column-gap: 8px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .ticket-sidebar-edit-buttons {
        grid-column: span 3;
    }
}

@media screen and (max-width: 576px) {
    .ticket-sidebar-edits {
        display: grid;
        column-gap: 8px;
        grid-template-columns: 1fr 1fr;
    }

    .ticket-sidebar-edit-buttons {
        grid-column: span 2;
    }
}

.tickets-table-new-message {
    font-weight: var(--font-weight-bold);
}

/* TODO remove this once not used (below) */
.ticket-table {
    width: 100%;
    height: fit-content;
    margin-top: 16px;
}

.ticket-table tbody tr {
    height: 8px;
}

.ticket-table thead tr {
    height: 8px;
    border-bottom: 1px solid red;
}

.ticket-table tbody tr td {
    border-bottom: 1px solid var(--divider);
    padding: 4px 0;
}

.ticket-table tr:hover {
    background-color: var(--table-row-hover);
}

.ticket-table tr th {
    width: fit-content;
    text-align: left;
}

.ticket-table tr td {
    font-size: var(--font-size-sm);
}

/* TODO remove this once not used (above) */

.tt-high-priority {
    background-color: var(--ticket-high-priority) !important;
}

.tt-urgent-priority {
    background-color: var(--ticket-urgent-priority) !important;
}

/*ST = SINGLE TICKET*/
.st-outer-wrapper {
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 0;
}

.st-inner-top-wrapper {
    background-color: var(--bg-light);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;

}

.st-top-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.st-top-title h1 {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-medium);
}

/* small phones */
@media screen and (max-width: 576px) {
    .st-inner-top-wrapper {
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        gap: unset;
    }

    .st-top-title {
        margin-top: 8px;
        justify-content: center;
    }

    .st-top-button-wrapper {
        justify-content: end;
    }
}

.st-top-button-wrapper {
    padding: 8px 0;
}

.date-time-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: var(--text-dark-faded-extra);
    font-size: var(--font-size-xs);
}

.ticket-contact-info {
    max-height: 350px;
    width: 100%;
    padding: 16px;
    position: relative;
    border-bottom: 1px solid var(--divider);
    transition: 0.5s;
}

.ticket-contact-info-closed {
    max-height: 0;
    width: 100%;
    padding: 0;
    position: relative;
    border-bottom: 1px solid var(--divider);
    transition: 0.5s;
}

.ticket-message-wrapper {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid var(--divider);
    color: var(--text-dark);
}

.ticket-message-wrapper-personal .tm-header {
    color: var(--main-blue);
}

.ticket-message {
    padding: 8px 16px;
    gap: 0;
}

.tm-first-message {
    background-color: var(--main-blue-bg);
}

.tm-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.tm-header:has(button) {
    /* The button adds the margin */
    margin-top: 0;
}

.tm-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 0;
}

.tm-body #tm-datetime {
    color: var(--text-dark-faded-extra);
}

.log-message-wrapper {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid var(--divider);
    background-color: var(--danger-bg);
    font-size: var(--font-size-sm);
    margin-bottom: 4px;
}

.st-new-message-wrapper {
    width: 100%;
    height: 100px;
    border-top: 1px solid var(--divider);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 16px;
    position: relative;
}

.ticket-text-area {
    border-radius: 0;
    margin: 0;
    height: 100%;
    padding: 6px 10px;
    outline: none;
    box-sizing: border-box;
    background-color: var(--bg-light);
    font-size: var(--font-size-normal);
    resize: none;
}

.ticket-text-area::placeholder {
    font-size: var(--font-size-normal);
}

.ticket-button {
    border-radius: 0;
    height: unset;
}

.close-ticket-text-area {
    width: 100%;
    height: 100%;
    padding: 6px 10px;
    outline: none;
    box-sizing: border-box;
    background-color: var(--bg-light);
    font-size: var(--font-size-normal);
    resize: none;
}

.close-ticket-text-area::placeholder {
    font-size: var(--font-size-normal);
}

.st-full-page-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
}

/* ADMIN SIDEBAR */

.as-header {
    color: var(--main-blue);
}

.as-section-item,
.as-section-value {
    font-weight: var(--font-weight-medium);
    color: var(--text-dark-faded);
    border-left: solid 4px var(--main-blue);
    padding-left: 8px;
}

.as-section-value:has(div:not(.date-closed)) {
    padding-left: 0;
    border-left: unset;
}

.as-section-value:has(div.profile-group) {
    padding-left: 8px;
    border-left: solid 4px var(--main-blue);
}

.as-section {
    height: fit-content;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.as-section>*:first-child {
    font-weight: var(--font-weight-medium);
}

.profile-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

@media screen and (max-width: 576px) {
    .as-section {
        padding: 4px 0;
    }

    .as-section:has(.as-section-value) {
        gap: 0;
    }

    .profile-cell {
        gap: 4px;
    }
}

@media screen and (max-width: 768px) {
    .as-wrapper {
        border-radius: 4px;
        border: 1px solid var(--divider);
        background-color: var(--bg-light);
        margin: 8px;
    }

    .as-header {
        padding-top: 8px;
    }

    .as-section-value {
        border-left: unset;
    }
}


/* LOG TIME POPUP */

.log-time-popup {
    max-width: 500px;
    width: fit-content;
    height: fit-content;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--bg-light);
    border: 3px solid var(--main-blue);
    z-index: 999;
    box-shadow: var(--box-shadow);
}

.log-time-popup div {
    width: 100%;
}

.log-time-popup div textarea {
    resize: none;
    width: 100%;
}

/* Advanced Filter Settings */

.ticket-filter-wrapper {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid var(--divider);
    background-color: var(--bg-light);
}

/* Dates */
.date-tomorrow {
    background-color: var(--main-blue-bg);
}

.date-today {
    background-color: var(--warning-bg);
}

.date-past {
    background-color: var(--danger-bg);
}

.date-future {
    background-color: var(--success-bg);
}