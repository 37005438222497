span[data-slate-placeholder="true"] {
    width: unset !important;
}

.slate-editable[contenteditable] {
    outline: 0px solid transparent;
}

.slate-editable {
    width: 100%; 
    white-space: pre-wrap;
    background-color: var(--bg-light);
}

.slate-editable p {
    white-space: pre-wrap;
}

.slate-editable:focus {
    border-color: var(--input-border-focus);
}

/** Other styles found at .markdown blockquote */
.slate-editable abbr {
    display: block;
}

.slate-popup {
    position: absolute;
    z-index: 99999;    
    top: -99999px;
    left: -99999px;
    padding: 4px;

    color: var(--text-dark);
    background-color: var(--main-blue-bg);
    border: 1px solid var(--input-border);
    border-radius: 8px 8px 0 0;
    box-shadow: var(--box-shadow);
    border-bottom: unset;
}

.slate-popup-item {
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    gap: 8px;
}

.slate-popup-item-group {
    color: var(--main-blue);
}

.slate-popup-item-selected {
    background-color: var(--main-blue-faded-extra);
    font-weight: var(--font-weight-medium);
    color: var(--text-dark);
}

.slate-popup-item-selected:hover,
.slate-popup-item:hover {
    background-color: var(--main-blue-hover);
    color: var(--text-light);
}

.user-mention {
    font-weight: var(--font-weight-medium); 
    background-color: var(--main-blue-faded-extra);
    border-radius: 4px;
    padding-inline: 4px;    
}
.user-mention::before{
    content: "@";
}

@media screen and (max-width: 768px) {
    .slate-popup-item-hint {
        display: none;
    }    
}